import React, { useLayoutEffect } from "react";
import { node } from "prop-types";

const OneTrustCookieSettingsButton = ({ children }) => {
	useLayoutEffect(() => {
		if (window.OneTrust == null) {
			// eslint-disable-next-line
			return;
		}

		try {
			window.OneTrust.initializeCookiePolicyHtml();
		} catch {
			// Ignoring the OneTrust error, because it will
			// be recovered by OneTrust itself
		}
	});

	return (
		<>
			{/* eslint-disable-next-line */}
			<a id="ot-sdk-btn" className="ot-sdk-show-settings">
				{children}
			</a>
			<style>{`
								#ot-sdk-btn.ot-sdk-show-settings, #ot-sdk-btn.optanon-show-settings {
									font-size: inherit;
									font-weight: 400;
									line-height: 1.5em;
									color: inherit;
									padding: 0;
									border: none;
									background: none;
								}
								#ot-sdk-btn.ot-sdk-show-settings:hover, #ot-sdk-btn.optanon-show-settings:hover {
									color: #DC5C00;
									background: none;
								}
								`}</style>
		</>
	);
};

OneTrustCookieSettingsButton.propTypes = {
	children: node,
};

export default OneTrustCookieSettingsButton;
