import React from "react";
import Hero from "@insightfulscience/atomic-react/Hero";
import HeroTitle from "@insightfulscience/atomic-react/Hero/Title";
import HeroDescription from "@insightfulscience/atomic-react/Hero/Description";
import Html from "@insightfulscience/smart-react/Html";
import HeroAction from "@insightfulscience/atomic-react/Hero/Action";
import HeroButtonLink from "@insightfulscience/atomic-react/Hero/ButtonLink";
import { useLocale } from "@insightfulscience/smart-react/i18";
import { Row, Col } from "@insightfulscience/atomic-react/Grid";
import routes from "../../routes";

const HeroSection = () => {
	const [t] = useLocale("home-page");

	return (
		<Hero
			spacing={{ py: 20 }}
			utility={{ bgColor: "dotmatics-8" }}
			sx={{ textAlign: "center", fontColor: "white" }}
		>
			<HeroTitle color="white" size="xxl">
				{t("HERO_TITLE")}
			</HeroTitle>
			<Row center>
				<Col lg={10}>
					<HeroDescription align="center">
						<Html>{t("HERO_DESCRIPTION")}</Html>
					</HeroDescription>
				</Col>
			</Row>
			<HeroAction>
				<HeroButtonLink size="s" href={routes.freeTrial}>
					{t("HERO_BTN_1")}
				</HeroButtonLink>
				<HeroButtonLink size="s" href={routes.howToBuy} styleType="light" isOutline>
					{t("HERO_BTN_2")}
				</HeroButtonLink>
			</HeroAction>
		</Hero>
	);
};

export default HeroSection;
