import React from "react";
import { useLocale } from "@insightfulscience/smart-react/i18";
import LandingSimpleSection from "@insightfulscience/atomic-react/LandingElement/SimpleSection";
import SmartTabs from "@insightfulscience/atomic-react/utils/SmartTabs";
import Tabs from "@insightfulscience/atomic-react/Tabs";
import { Col, Row } from "@insightfulscience/atomic-react/Grid";
import { TabCol } from "@insightfulscience/atomic-react/Tabs/Grid";
import TabTitle from "@insightfulscience/atomic-react/Tabs/Title";
import Line from "@insightfulscience/atomic-react/Line";
import Button from "@insightfulscience/atomic-react/Button";
import Paragraph from "@insightfulscience/atomic-react/Paragraph";
import Tab1 from "./FeatureTab1";
import Tab2 from "./FeatureTab2";
import Tab3 from "./FeatureTab3";
import Tab4 from "./FeatureTab4";
import routes from "../../../routes";

export default () => {
	const [t] = useLocale("home-page");

	return (
		<>
			<LandingSimpleSection isBorderNone>
				<LandingSimpleSection.Title>{t("SECTION_1_TITLE")}</LandingSimpleSection.Title>
				<Paragraph sx={{ fontSize: "l", textAlign: "center" }} spacing={{ mb: 0 }}>
					{t("SECTION_1_DESCRIPTION")} {t("SECTION_2_DESCRIPTION")}
				</Paragraph>
			</LandingSimpleSection>
			<LandingSimpleSection isBorderNone spacing={{ py: 0 }}>
				<LandingSimpleSection.Body>
					<SmartTabs initialActiveTab="advantages-easy-of-use">
						<Tabs isBorderBottom={false} mx>
							<TabCol md="auto">
								<SmartTabs.Tab id="advantages-easy-of-use" px={false} Component={TabTitle}>
									{t("ADVANTAGES_TAB_1_TITLE")}
								</SmartTabs.Tab>
							</TabCol>
							<TabCol md="auto">
								<SmartTabs.Tab id="advantages-powerful-tools" px={false} Component={TabTitle}>
									{t("ADVANTAGES_TAB_2_TITLE")}
								</SmartTabs.Tab>
							</TabCol>
							<TabCol md="auto">
								<SmartTabs.Tab id="advantages-central-repository" px={false} Component={TabTitle}>
									{t("ADVANTAGES_TAB_3_TITLE")}
								</SmartTabs.Tab>
							</TabCol>
							<TabCol md="auto">
								<SmartTabs.Tab id="advantages-integrated-platform" px={false} Component={TabTitle}>
									{t("ADVANTAGES_TAB_4_TITLE")}
								</SmartTabs.Tab>
							</TabCol>
						</Tabs>
						<Row className="tabs-content">
							<SmartTabs.TabBody.Mounted id="advantages-easy-of-use" Component={Col}>
								<Tab1 />
							</SmartTabs.TabBody.Mounted>
							<SmartTabs.TabBody.Mounted id="advantages-powerful-tools" Component={Col}>
								<Tab2 />
							</SmartTabs.TabBody.Mounted>
							<SmartTabs.TabBody.Mounted id="advantages-central-repository" Component={Col}>
								<Tab3 />
							</SmartTabs.TabBody.Mounted>
							<SmartTabs.TabBody.Mounted id="advantages-integrated-platform" Component={Col}>
								<Tab4 />
							</SmartTabs.TabBody.Mounted>
						</Row>
					</SmartTabs>
				</LandingSimpleSection.Body>
				<Row spacing={{ mt: 12 }}>
					<Col sx={{ textAlign: "center" }}>
						<Button.Link styleType="primary" size="s" href={routes.freeTrial}>
							Try for Free
						</Button.Link>
					</Col>
				</Row>
				<Line spacing={{ mt: 16 }} />
			</LandingSimpleSection>
		</>
	);
};
